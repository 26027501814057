export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Home',
    action: 'read'
  },
  {
    title: 'Cuti',
    route: 'cuticard',
    icon: 'NavigationIcon',
    resource: 'Cuti',
    action: 'read'
  },
  {
    title: 'Cuti Melahirkan',
    route: 'maternity',
    icon: 'HeartIcon',
    resource: 'Maternity',
    action: 'read'
  },
  {
    title: 'Surat Sakit',
    route: 'SickCard',
    icon: 'ActivityIcon',
    resource: 'Sick',
    action: 'read'
  },
  {
    title: 'Komunikasi',
    route: 'comunication',
    icon: 'RadioIcon',
    resource: 'Komunikasi',
    action: 'read'
  },
  {
    title: 'Tukar Dinas',
    route: 'scheduleExchange',
    icon: 'ShuffleIcon',
    resource: 'Exchange',
    action: 'read'
  },
  // {
  //   title: 'SPO',
  //   route: 'spovideo',
  //   icon: 'AirplayIcon',
  // },
  {
    title: 'Jadwal Kerja',
    route: 'schedule',
    icon: 'CalendarIcon',
    resource: 'Schedule',
    action: 'read'
  },
]
